<template>
  <b-row>
    <b-col
      cols="12"
      md="12"
    >
      <div class="contact-box center-version">
        <div class="top">
          <h3 class="m-b-xs">
            <strong> {{ viewData.title }} </strong>
          </h3>
          <div class="font-bold">
            @{{ viewData.event_expire_date }}
          </div>
        </div>
        <div class="user-info">
          <ul>
            <!-- <li>
              <feather-icon
                icon="MailIcon"
              /> {{ viewData.email }}
            </li> -->
            <!-- <li>
              <feather-icon
                icon="UsersIcon"
              /> {{ viewData.gender }}
            </li> -->
            <!-- <li>
              <feather-icon
                icon="ServerIcon"
              /> {{ resolveRoleName(viewData.role) }}
            </li> -->
            <!-- <li>
              <feather-icon
                icon="PhoneIcon"
              /> {{ viewData.phone }}
            </li> -->
            <li>
              <feather-icon
                icon="ActivityIcon"
              /> {{ resolveActiveUser(viewData.is_active) }}
            </li>
          </ul>
        </div>
        <div class="contact-box-footer">
          <h5>Other Details</h5>
          <div class="m-t-xs btn-group">
            <ul>
              <li> <strong>Voucher Price: </strong>{{ viewData.amount }} {{ resolveCashBackType(viewData.amount_type) }}</li>
              <li> <strong>Quantity Limit: </strong>{{ viewData.quantity }} </li>
            </ul>
          </div>
        </div>

      </div>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */

import axios from 'axios'
import voucher from '../vouchers'

export default {
  data() {
    return {
      current: '',
      viewData: {},
      id: this.$store.state.generalIds.id,
    }
  },
  setup() {
    const { resolveCashBackType, resolveActiveUser } = voucher()

    return {
      resolveCashBackType,
      resolveActiveUser,
    }
  },
  computed: {
    currentDate() {
      const today = new Date()
      // eslint-disable-next-line no-return-assign
      return this.current = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
    },
  },
  mounted() {
    this.getdata()
  },
  methods: {
    getdata() {
      axios.get(`vouchers/${this.id}`).then(res => {
        this.viewData = res.data.data
      })
    },
  },
}
</script>

<style lang="scss">
/* CONTACTS */
.contact-box {
  padding: 20px;
  margin-bottom: 20px;
}
.contact-box > a {
  color: inherit;
}
.contact-box.center-version {
  padding: 0;
}
.contact-box.center-version > .top {
  display: block;
  padding: 5px;
  text-align: center;
}
.contact-box.center-version > .top img {
  width: 80px;
  height: 80px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.contact-box.center-version address {
  margin-bottom: 0;
}
.contact-box .contact-box-footer {
  border-top: 1px solid #e7eaec;
  padding: 15px 20px;
}
a{
    text-decoration:none !important;
}

.user-info ul {
  list-style: none;
  li {
    padding-top: 1rem;
  }
}
</style>
