import { ref } from '@vue/composition-api'

export default function voucher() {
  const userForm = ref({
    title: '',
    amount_type: '',
    amount: 100,
    min_amount: 100,
    max_amount: 500,
    event_expire_date: '',
    expire_days: 1,
    type: 1,
    quantity: 0,
    user_limit: 0,
    min_deposit: 0,
    max_deposit: 0,
    transaction_no: 0,
    transaction_mini_value: 0,
    transaction_type: '',
  })

  const viewData = ref({})

  const typeOptions = [
    { text: 'Cash in', value: 1 },
    { text: 'Transaction', value: 2 },
    { text: 'Download', value: 3 },
  ]

  const transactionTypeOptions = [
    { text: 'Please select an option', value: '' },
    { text: 'Order', value: 1 },
    { text: 'Deposit', value: 2 },
    { text: 'Withdrwal', value: 3 },
  ]

  const amountpercentage = [
    { text: 'Amount', value: 'amount' },
    { text: 'Percentage', value: 'percentage' },
  ]

  const resolveCashBackType = role => {
    if (role === 'percentage') return '%'
    if (role === 'amount') return 'amount'

    return 'N/A'
  }
  const resolveActiveUser = role => {
    if (role === true) return 'Active'

    return 'Not Active'
  }

  return {
    userForm,
    typeOptions,
    amountpercentage,
    transactionTypeOptions,
    viewData,
    resolveCashBackType,
    resolveActiveUser,
  }
}
