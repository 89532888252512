<template>
  <div>
    <general-table
      ref="voucherTable"
      :api-url="APIURL"
      :add-type="addType"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :columns="columns"
      :type="type"
      guard="offers"
    >
      <template #cell(type)="data">
        {{ getTypes(data.item.type) }}
      </template>
    </general-table>
    <div>
      <b-modal
        ref="view-modal"
        hide-footer
        centered
      >
        <view-voucher />
      </b-modal>
    </div>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import ViewVoucher from './control-voucher/ViewVoucher.vue'

export default {
  components: { GeneralTable, ViewVoucher },
  data() {
    return {
      APIURL: 'vouchers',
      addType: 'Add Voucher',
      addComponentName: 'add-voucher',
      editComponent: 'edit-voucher',
      type: 'page',
      columns: [
        'select',
        { key: 'id', sortable: true },
        { key: 'title', label: 'Title' },
        { key: 'type', label: 'Type' },
        { key: 'event_expire_date', label: 'Event Expire Date', sortable: true },
        { key: 'amount', label: 'Amount', sortable: true },
        { key: 'quantity', label: 'Quantity' },
        { key: 'no_usage', label: 'No Usage', sortable: true },
        { key: 'user_limit', label: 'User Limit' },
        { key: 'is_active', label: 'Active' },
        { key: 'actions' },
      ],
    }
  },
  methods: {
    getTypes(type) {
      if (type === 1) {
        return 'Cashin'
      } if (type === 2) {
        return 'Transaction'
      }
      return 'Download'
    },
  },
}
</script>

<style>

</style>
